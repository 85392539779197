





















import { Component, Prop } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import SlideWalkerLoader from '@/mixins/SlideWalkerLoader'
import slideComponents from '@/slide-components'
import RootSlide from '@/mixins/RootSlide'
import EventType from '@/constants/event-type'
import { resolveSlug } from '@/utils/alias-resolver'

@Component({
    components: {
        ...slideComponents
    }
})
export default class SlideCollection extends mixins(RootSlide, SlideWalkerLoader) {
    @Prop() y!: number
    @Prop() endDrag!: Function

    numMountedSlides = 0
    allSlidesMounted = false

    resolveWalkerSlug(walker: SlideWalker | PresentationWalker) {
        return resolveSlug(walker)
    }

    dispatchAssetsLoaded() {
        this.$emit(EventType.ASSETS_LOADED)
    }

    onCurrentSlideAssetsLoaded() {
        this.dispatchAssetsLoaded()
    }

    filterSlideIndex(value: number) {
        return value + 1
    }

    filterNumberSlides(value: number) {
        return value + 1
    }

    onSlideMounted() {
        this.numMountedSlides++
        this.allSlidesMounted = this.numMountedSlides === this.filteredChildren.length
    }
}
